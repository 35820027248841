import Pages from './data/page_data';
import router from '../../router';

const state = {
    pages: Pages,
    navigationPages: [],
    currentPage: { display_name: Pages[0].lng_name, id: Pages[0].id } // Dashboard default
};

const getters = {
    getAllPages: state => state.pages,
    getAllNavigationPages: state => state.navigationPages,
    getCurrentPage: state => state.currentPage 
};

const actions = {
    // Make the tab active
    makeActiveTab({ commit }, page)
    {
        commit('makeCurrentTab', { display_name: page.lng_name, id: page.id, icon: page.icon });
    },

    // Add tab to nav bar
    addTab({ commit }, page)
    {
        commit('addTabToNav',  { display_name: page.lng_name, id: page.id, icon: page.icon });
    },

    // Remove tab from nav bar
    removeTab({ commit }, page)
    {
        commit('removeTabFromNav',  { display_name: page.lng_name, id: page.id, icon: page.icon });
    },

    // Remove tab from nav bar
    closeTab({ commit }, pageId)
    {
        if (state.navigationPages.length > 1) // If we have more than one page open
        {
            commit('removeTabFromNav', pageId); // Delete the tab from list

            if (pageId == state.currentPage.id) // If we delete tab we are currently showing, we show the next...
            {
                let redirectId = state.navigationPages[state.navigationPages.length - 1].id;
                router.push({name: state.pages[redirectId].name});
            }
        }
        else // If we have only one page open
        {
            if (pageId != 0) // If the page is not dashboard, close it
            {
                commit('removeTabFromNav', state.pages[pageId].id);
                router.push({name: 'Dashboard'});
            }
        }
    },
};

const mutations = {
    makeCurrentTab: (state, page) => 
    {
        state.currentPage = page;
    },
    addTabToNav: (state, page) => 
    {
        if (page.display_name === "")
        {
            // Automaticky doplní "Detail auta" apod. stringy, pokud by tab měl být prázdný
            page.display_name = state.pages.filter((e) => {return e.id == page.id})[0].lng_name
        }

        for (var i = 0; i < state.navigationPages.length; i++)
        {
            var temp = state.navigationPages[i];

            if (temp.id == page.id)
            {
                // Stránku již v navigaci máme, pouze aktualizujeme její nadpis, její obsah se aktualizuje sám
                state.navigationPages[i].display_name = page.display_name
                return;
            }
        }

        state.navigationPages.push(page);
    },
    removeTabFromNav: (state, pageId) => 
    {
        for (var i = 0; i < state.navigationPages.length; i++)
        {
            var temp = state.navigationPages[i];

            if (temp.id == pageId)
            {
                state.navigationPages.splice(i, 1);
                break;
            }
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
