const alerts = {
    install(Vue) {
        const Toast = Vue.swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: false,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Vue.swal.stopTimer)
                toast.addEventListener('mouseleave', Vue.swal.resumeTimer)
            }
        })
        Vue.prototype.$alerts = {
            confirmDelete: (title, text='') => Vue.swal.fire({
                    title,
                    text,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                confirmButtonText: 'Ano, smazat',
                cancelButtonText: 'Ne'
            }),

            /**
             * title,
             * text,
             * icon //success, warning, error, info, question
             */
            message: (title, text = '', icon = '') => Vue.swal.fire({
                title,
                text,
                icon //success, warning, error, info, question
            }),

            toast: (title, icon = '') => Toast.fire({title, icon})
            
        }
    }
}
  
export default alerts