<template>
    <aside class="main-sidebar">
    <!-- sidebar: style can be found in sidebar.less -->
    <section class="sidebar">
      <!-- Sidebar user panel -->
      <!-- search form -->
        <!-- <form action="#" method="get" class="sidebar-form">
          <div class="input-group">
            <input type="text" name="q" class="form-control" placeholder="Search...">
            <span class="input-group-btn">S
                  <button type="submit" name="search" id="search-btn" class="btn btn-flat"><i class="fa fa-search"></i>
                  </button>
                </span>
          </div>
        </form> -->
      <!-- /.search form -->
      <!-- sidebar menu: : style can be found in sidebar.less -->
      <ul class="sidebar-menu">
        <!-- <li>
          <a :href="'/#'" id="menu_dashboard">
            <i class="fa fa-plus text-success"></i> <span style="font-weight: bold">Vytvořit záznam</span>
          </a>
        </li> -->
        <li v-for="page in this.getAllPages.filter(page => { return (page.in_sid && ((page.meta.role == 'all') || (page.meta.role == 'mnt' && user.cus) || (page.meta.role == 'acc' && !user.cus))) })" :key="page.id">
          <a @click="passToLink" :href="'/#' + page.path" id="menu_dashboard">
            <i class="fa" :class="page.icon"></i> <span>{{ page.lng_name }}</span>
          </a>
        </li>
      </ul>

      <ul class="sidebar-menu"  style="position: absolute; bottom: 0; margin-bottom: 20px; flex-shrink: 0;">
        <li>
          <a href="#" id="menu_dashboard" style="padding: 0px">
            <span style="">
              <div class="row">
              <a href="https://anafra.cz/"><img style="display: block; margin-left: auto; margin-right: auto; width: 50%" src="../assets/h_grey.png" alt="ANASAP"></a>
              <div class="col-xs-12" style="margin-top: 10px;">
                <span style="margin-left: 22%; color: #B8C7CE;">Powered by <a href="https://adminlte.io/">AdminLTE</a></span>
              </div>
            </div>
            </span>
          </a>
        </li>
      </ul>
    </section>   
  </aside>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: 'Sidebar',
    methods: {
        passToLink(e)
        {
            document.querySelector("body").classList.toggle("sidebar-open") // hides menu when transitioning between pages

            if (this.group.access == 0)
            {
                e.preventDefault()

                this.$swal({
                    title: 'K této agendě nemáte přístup.',
                    icon: 'warning',
                    html: 'Pro přístup je nutné mít validní předplatné. Zakoupit ho můžete ve svém <a href="/#/personal_info">osobním nastavení</a>.',
                });
            }
        }
    },
    computed: {
        ...mapGetters(["getAllPages", 'user', 'group'])
    }
}
</script>
