import Vue from 'vue';
import Router from 'vue-router';
import Pages from './store/modules/data/page_data';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

Vue.use(Router);

const routes = [];

// Pages for all users
Pages.forEach((item) => {
    routes.push({
      path: item.path,
      name: item.name,
      component: () => import(/* webpackChunkName: "about" */ `./views/${item.name}.vue`),
      meta: item.meta
  });
});

// Root redirected to login
routes.push({
  path: "/",
  redirect: '/login',
  meta: {
    guest: true
  }
});

// Login page for guest
routes.push({
  path: "/login",
  name: "Login",
  component: () => import(/* webpackChunkName: "about" */ `./views/Login.vue`),
  meta: {
    guest: true
  }
});

// Email verified info page for guest
routes.push({
  path: "/register/verified",
  name: "Verified",
  component: () => import(/* webpackChunkName: "about" */ `./views/Verified.vue`),
  meta: {
    guest: true
  }
});

// Email verification invalid info page for guest
routes.push({
  path: "/register/invalid_link",
  name: "InvalidLink",
  component: () => import(/* webpackChunkName: "about" */ `./views/InvalidLink.vue`),
  meta: {
    guest: true
  }
});

// Email verification sent info page for guest
routes.push({
  path: "/register/verification_sent",
  name: "SentVerificationEmail",
  component: () => import(/* webpackChunkName: "about" */ `./views/SentVerificationEmail.vue`),
  meta: {
    guest: true
  }
});

// Register page for guest
routes.push({
  path: "/register",
  name: "Register",
  component: () => import(/* webpackChunkName: "about" */ `./views/Register.vue`),
  meta: {
    guest: true
  }
});


const router = new Router({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    if (localStorage.getItem('accessData') == null) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    } else {
      let user = JSON.parse(localStorage.getItem('user'))
      if (to.matched.some(record => record.meta.role)) {
        // Send people without valid subscription to dashboard
        if (user.cus == 0 && (to.name != "Dashboard" && to.name != "PersonalInfo") && (user.acc == 0)) {
          next({ name: 'Dashboard' });

        // Check if user has access rights to pages
        } else if (to.meta.role == 'all') {
          next()
        } else if (to.meta.role == 'acc' && user.cus == 0) {
          next();
        } else if (to.meta.role == 'mnt' && user.cus == 1) {
          next()
        }
        else{
          next({ name: 'Forbidden'})
        }
      } else {
        next()
      }
    }
  } else if(to.matched.some(record => record.meta.guest)) {
      if(localStorage.getItem('accessData') == null){
          next()
      }
      else{
          next({ name: 'Dashboard'})
      }
  } else {
      next() 
  }
})


export default router;
