/**
 * Class for comfortable communication with backend API.
 */

// Importing http library
import axios from 'axios';

import router from '../../router';

import conf from './apiConfig'

conf.jwtCheckInit();

const api = {
    
    /**
     * Getting data from app's database.
     * @param {*} path 
     */
    async get(path) {
        try {
            // Fetch data
            const res = await axios.get(conf.host + path, conf.api_config)
            // Return the data
            return res.data;
        } catch (error) {
            console.error(error);
        }
    },

    /**
     * Posting data into the backend API
     * @param {*} path 
     * @param {*} data 
     */
    async post(path, data) {
        try {
            // Send data
            const res = await axios.post(conf.host + path, data , conf.api_config);
            // Return the response data
            return res.data;
        } catch (error) {
            if (error.response.status == 400 || error.response.status == 500) 
                return error.response.data;
            console.error(error.response.data)
            console.error(error);
        }
    },

    /**
     * Posting FormData into the backend API
     * @param {*} path 
     * @param {*} data 
     */
    async postFormData(path, data) {
        try {
            // Send data
            let customConfig = conf.api_config;
            customConfig.headers['Content-Type'] = "multipart/form-data";

            const res = await axios.post(conf.host + path, data , customConfig);
            // Return the response data
            return res.data;
        } catch (error) {
            if (error.response.status == 400 || error.response.status == 500) 
                return error.response.data;
            console.error(error.response.data)
            console.error(error);
        }
    },

    /**
     * Updating data in the DB.
     * @param {*} path 
     * @param {*} data 
     */
    async put(path, data) {
        try {
            // Send data
            const res = await axios.put(conf.host + path, data , conf.api_config);
            // Return the response data
            return {status: res.status, data: res.data}
        } catch (error) {
            console.error(error.response.data.msg)
            console.error(error);
            return {status: error.response.status, data: error.response.data}
        }
    },

    /**
     * Deleting or archiving data in the DB.
     * @param {*} path 
     */
    async delete(path) {
        try {
            // Fetch data
            const res = await axios.delete(conf.host + path, conf.api_config)
            // Return the data
            return res.data;
        } catch (error) {
            console.error(error.response.data.msg)
            console.error(error);
        }
    },
    
    /**
     * Logging in the app.
     * @param {*} email 
     * @param {*} password 
     */
    async login(email, password) {
        try {
            const config = {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            };
            // Fetch data
            const res = await axios.post(conf.host + 'auth/login', JSON.stringify({
                email,
                password
            }), config);
            
            // Storing credentials to local storage
            if (res.data !== undefined) {
                localStorage.setItem('user', JSON.stringify(res.data.user));
                localStorage.setItem('accessData', res.data.accessData);
                localStorage.setItem('refreshData', res.data.refreshData);
                conf.jwtReloadConf();
            }

            // Returning response
            return res.data;
        } catch (error) {
            throw error;
        }
    },

    async register(company_name, email, password, ico) {
        try
        {
            const config = {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            };

            // Send data
            const res = await axios.post(conf.host + 'auth/register', JSON.stringify({
                company_name,
                ico,
                email,
                password
            }), config);
            
            // Returning response
            return res.data;
        }
        catch (error)
        {
            // Error is general javascript error, error.response returns the response
            throw error.response.data;
        }
    },

    /**
     * Logging out from the app.
     */
    async logout() {
        try{
            await axios.post(conf.host + 'auth/logout', JSON.stringify({
                refreshData: localStorage.getItem('refreshData')
            }), {headers: {'Content-Type': 'application/json' }, withCredentials: true })
        }catch (error){
            console.error(error);
        } finally {
            localStorage.clear();
            router.push({name: 'Login'});
        }
    },

    async changePassword(data) {
        try {
            const config = {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            };

            // Fetch data
            const res = await axios.put(conf.host + 'auth/password', JSON.stringify(data), config);
            
            // Returning response
            return res.data;
        } catch (error) {
            throw error;
        }
    }
}

export default api;