<template>
  <div v-if="(this.$route.path != '/register') && (this.$route.path != '/login') && (this.$route.path != '/register/verified') && (this.$route.path != '/register/invalid_link') && (this.$route.path != '/register/verification_sent') && (this.$route.path != '/')" id="app" class="wrapper">    
    <div>
      <Navbar />
      <Sidebar />
      <div class="content-wrapper half-background">
      <br  class="nav nav-tabs hidden-xs">
      <div class="nav-tabs-custom" >
        <!-- <a class="hidden-xs " href="https://anafra.cz/"><img class="pull-right" style="height: 25px; margin-right: 20px" src="./assets/h_flat.png" alt="ANASAP"></a> -->
        <NavTabs />
        <div class="tab-content content" style="background-color: #EEEFF4;" id="tab_contents">
          <h3 class="visible-xs">{{getAllPages[getCurrentPage.id].lng_name}}</h3>
          <!-- <transition name="fade" mode="out-in"> -->
          <router-view/>
          <!-- </transition> -->
        </div>
      </div>
      </div>
    </div>
  </div>
  <transition v-else name="fade" mode="out-in">
    <router-view />
  </transition>
</template>

<script>
    import Navbar from './components/Navbar';
    import Sidebar from './components/Sidebar';
    import NavTabs from './components/NavTabs'
    import { mapGetters, mapActions } from "vuex";

    export default {
        name:"app",
        components: {
            Navbar,
            Sidebar,
            NavTabs
        },
        computed: {
            ...mapGetters(["getAllPages", "getAllNavigationPages", "getCurrentPage"])
        },
        methods: {
            ...mapActions(["removeTab", "makeActiveTab"]),
        }
    }
</script>


<style>
    .login-box input, .register-box input {
        border-radius: 12px;
        border-width: 2px;
        height: 38px;
        border-color: #b9bdc5;
    }

    .login-box .form-group, .register-box .form-group {
        margin-bottom: 15px;
    }

    .login-box input:focus, .register-box input:focus {
        border-color: #2a1a58;
    }

    @media (max-width: 375px) {
        .register-logo {
            width: 60%;
        }
    }

    .anasap-link {
        display: block;
        font-weight: 700;
        color: #2a1a58;
        font-size: 1.75rem;
        font-family: "Montserrat", sans-serif !important;
    }

    .anasap-link:hover {
        color: #ffb65a;
    }

    .anasap-btn[disabled] {
        background-color: #b9bdc5;
        color: white;
    }

    .anasap-btn {
        display: block;
        padding: 0.75rem 2rem;
        background-color: #ffb65a;
        border-radius: .5em;
        font-size: 1.1em;
        box-shadow: 0 2px 15px 2px #d0d0d0;
        -webkit-box-shadow: 0 2px 15px 2px #d0d0d0;
        text-transform: uppercase;
        line-height: 1.5;
        font-weight: 700;
        color: #2a1a58;
        text-align: center;
        cursor: pointer;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border: 1px solid transparent;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }

    .anasap-btn:not(:disabled):hover {
        background-color: #ffa538!important;
    }

  .fade-enter-active, .fade-leave-active {
      transition: opacity .3s
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
      opacity: 0
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.4;
    background-color: #ECF0F5;
  }

  .btn {
    display: inline-block;
    border: none;
    background: #555;
    color: #fff;
    padding: 7px 20px;
    cursor: pointer;
  }
  .btn:hover {
    background: #666;
  }

  div.half-background{
      background: linear-gradient(to bottom, white 10%, #EEEFF4 90%);
  }

  /* Forbid arrows in input type number for Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Forbid arrows in input type number for Firefox */
  input[type=number] {
    -moz-appearance:textfield;
  }

  .swal2-popup {
  font-size: 1.5rem !important;
}
</style>

<style lang="scss">
  @import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
  @import "../node_modules/ag-grid-community/dist/styles/ag-theme-balham.css";
  @import "~vue-directive-tooltip/src/css/index.scss";
</style>
