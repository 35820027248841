import Vue from 'vue';
import Vuex from 'vuex';
import Pages from './modules/pages';
import User from './modules/user';
import Customers from './modules/customers';
import Cars from './modules/cars';
import Issues from './modules/issues';
import Maintainers from './modules/maintainers';
import Operations from './modules/operations';
import Billing from './modules/billing';
import Emails from './modules/emails';

//Load Vuex
Vue.use(Vuex);

//Create store
export default new Vuex.Store({
    modules:{
        Pages,
        User,
        Customers,
        Cars,
        Issues, 
        Maintainers,
        Billing,
        Operations,
        Emails
    }
});