/**
 * This vuex store is used for manipulation with data about operations of the user.
 */

import api from '../classes/api';
import router from '../../router';

const state = {
    operations: [],
    operation: {},
    operation_id: null,
};

const getters = {
    allListOperations: state => state.operations,
    currentOperation: state => state.operation,
    currentOperationId: state => state.operation_id,
};

const actions = {
    async fetchListOperations({ commit }) {
        const res = await api.get('operations');
        commit('setListOperations', res);
    },

    async fetchListOperation({ commit }, id) {
        const res = await api.get(`operations/${id}`);
        commit('setListOperation', { ...res, dph: res.dph + "%" });
    },

    makeCurrentListOperation({ commit }, id) {
        router.push({name: 'OperationDetail'});
        commit('setCurrentListOperationId', id)
    },

    async addListOperation({ commit }, data) {
        const res = await api.post(`operations`, data);
        
        if (res.code != "EXCEPTION")
        {
            commit('newListOperation', data);
        }

        return res;
    },

    async updateListOperation({ commit }, data) {
        const res = await api.put(`operations/${data.id}`, data);

        if (res.status == 200)
        {
            commit('updateListOperation', data);
            return res.data
        }
        else
        {
            throw new Error(`Status: ${res.status}\n ${res.data}`);
        }
        
    },

    async deleteOperation({ commit }, id)
    {
        const res = await api.delete(`operations/${id}`);

        if (res.msg == "Success.")
        {
            commit('removeOperationList', id);
        }

        return res;
    }
};

const mutations = {
    setListOperations: (state, operations) => (state.operations = operations),
    setListOperation: (state, operation) => (state.operation = operation),
    setCurrentListOperationId: (state, operation_id) => (state.operation_id = operation_id),
    newListOperation: (state, operation) => state.operations.push(operation),
    removeOperationList: (state, id) =>state.operations = state.operations.filter(operation => operation.id !== id),
    updateListOperation: (state, updateOperation) => {
        state.operations.findIndex(operation => operation.id === updateOperation.id);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};