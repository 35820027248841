/**
 * This vuex store is used for manipulation with data about customers of the user.
 */

import api from '../classes/api';
import router from '../../router';

const state = {
  customers: [],
  minCustomers: [],
  customer: {},
  cus_id: null
};

const getters = {
  allCustomers: state => state.customers,
  currentCustomer: state => state.customer,
  currentCustomerId: state => state.cus_id,
  minCustomers: state => state.minCustomers,
};

const actions = {
  async fetchCustomers({ commit }) {
    const res = await api.get('customers');
    commit('setCustomers', res.cus);
  },
  
  async fetchMinCustomers({ commit }) {
    const res = await api.get('customers/min');
    commit('setMinCustomers', res.cus);
  },

  async fetchCustomer({ commit }, id) {
    const res = await api.get(`customers/${id}`);
    const maintainers = await api.get(`customers/${id}/maintainers`);
    const cars = await api.get(`customers/${id}/cars`);
    const issues = await api.get(`customers/${id}/issues`);
    
    res.maintainers = maintainers; 
    res.cars = cars;
    res.issues = issues;

    commit('setCustomer', res);
  },

  makeCurrentCus({ commit }, id) {
    router.push({name: 'CustomerDetail'});
    commit('setCurrentCusId', id)
  },

  async addCustomer({ commit }, data) {
    const res = await api.post(`customers`, data);
    
    if(!res.existing)
    commit('newCustomer', data);
    return res;
  },

  async updateCustomer({ commit }, data) {
    const res = await api.put(`customers/${data.customerid}`, data);

    if (res.status == 200) {
      commit('updateCustomer', data);
    } else {
      throw new Error(`Status: ${res.status}\n ${res.data}`);
    }
  },

  async deleteCustomer({ commit }, id) {
    const res = await api.delete(`customers/${id}`);

    if(res.msg == "Success.")
      commit('removeCustomer', id);
    
  }
};

const mutations = {
  setCustomers: (state, customers) => (state.customers = customers),
  setMinCustomers: (state, customers) => (state.minCustomers = customers),

  setCustomer: (state, customer) => (state.customer = customer),

  setCurrentCusId: (state, cus_id) => (state.cus_id = cus_id),

  newCustomer: (state, customer) => state.customers.push(customer),

  removeCustomer: (state, id) =>
    (state.customers = state.customers.filter(customer => customer.customerid !== id)),
  
  updateCustomer: (state, updCustomer) => {
    state.customers.findIndex(customer => customer.id === updCustomer.id);
  }

};

export default {
  state,
  getters,
  actions,
  mutations
};
