/**
 * This vuex store is used for manipulation with billing data.
 */

import api from '../classes/api';

const state = {
    subscription: {},
    invoiceInfo: {}
};

const getters = {
    subscription: state => state.subscription,
    invoiceInfo: state => state.invoiceInfo
};

const actions = {
    /**
     * Fetch billing data
     */
    async fetchInvoiceInfo({ commit }) {
        const res = await api.get('billing/organization');
        commit('setInfo', res.org);
    },
    /**
     * 
     * @param {*} param0 
     * @param {*} data object with specified parameters that ought to be changed
     */
    async updateInvoiceInfo({ commit }, data) {
        const res = await api.put('billing/organization', data);

        if (res.status == 200) {
            console.log(res);
            commit('setInfo', res.org);
        } else {
            throw new Error(`Status: ${res.status}\n ${res.data}`);
        }
    },
};

const mutations = { 
    setSub: (state, subscription) => (state.subscription = subscription),
    setInfo: (state, invoiceInfo) => (state.invoiceInfo = invoiceInfo),
};

export default {
  state,
  getters,
  actions,
  mutations
};