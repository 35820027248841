/**
 * Single line comments with * symbol are ideas that are not urgent or necessary for
 * overall function of the app, but would be nice for us programmers and actual clearance of the code.
 */

// Import vue modules
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Import styling modules
import 'expose-loader?$!expose-loader?jQuery!jquery'
// import jQuery from 'expose-loader?$!expose-loader?jQuery!jquery'
// import 'jquery-slimscroll'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'font-awesome/css/font-awesome.min.css'
import 'ionicons/dist/css/ionicons.min.css'
import './assets/css/AdminLTE.min.css'
import './assets/css/skins/_all-skins.min.css'
import './assets/css/fonts.css'

import 'fastclick'
import './assets/js/adminlte.min.js'
import 'jquery-ui'

// Importing axios
import Axios from 'axios'

// Creating vue alias for axios
Vue.prototype.$http = Axios;

// Tooltips
import Tooltip from 'vue-directive-tooltip';
import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css';
Vue.use(Tooltip);

// Vuelidate
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

// Import mapActions and nap Getters
// ...

// Alerts
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

import alerts from './assets/swal';
Vue.use(alerts);

import helpers from './assets/helpers';
Vue.use(helpers);

// Production tip
Vue.config.productionTip = false

import Vcalendar from 'v-calendar'
// import '../node_modules/v-calendar/lib/v-calendar.min.css';

Vue.use(Vcalendar, {             // second is optional
  datePickerTintColor: '#F00',
  datePickerShowDayPopover: false
})

// App init
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
