<template>
    <ul class="nav nav-tabs hidden-xs" id="tab_selection">
        <li v-for="page in getAllNavigationPages" :key="page.id" class="ui-sortable-handle" :class="{'active':(page.id == getCurrentPage.id)}">
          <a v-on:click.self="changePage(page.id)" class="parent">
          <!-- <a :href="'#' + page.path" class="parent"> -->
            <i style="margin-right: 5px" class="fa" :class="page.icon"></i>{{ page.display_name }}
            <a class="exit_tab" v-on:click="closeTab(page.id)">
                <i class="fa fa-times"></i>
            </a>
          </a>
        </li>
    </ul>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import $ from 'jquery'

export default {
    name: "NavTabs",
    computed: {
        ...mapGetters(["getAllPages", "getAllNavigationPages", "getCurrentPage"]),
        console: () => console
    },
    methods: {
        ...mapActions(["removeTab", "makeActiveTab", 'closeTab']),
        changePage(pageId)
        {
            if (this.getCurrentPage.id != pageId)
            {
                this.$router.push({name: this.getAllPages[pageId].name});
            }
        }
    }
}

$('#parent').on('click', function(event) {
  if(event.target == event.currentTarget) {
    console.log('Parent element clicked!');
  } else {
    console.log('Child element clicked!');
  }
});

</script>

<style scoped>
  .nav-tabs-custom > .nav-tabs > li.active {
    border-top-color: rgb(87, 87, 87);
    background-color: #EEEFF4 !important;
  }
  .nav-tabs-custom > .nav-tabs > li {
      border-top-color: #EEEFF4;
      cursor: pointer;
  }
  a.exit_tab{
      border: none;
      background: none;
      margin-left: 10px;
      color: black;
  }
  a.exit_tab:hover{
      cursor: pointer;
      margin-left: 10px
  }
  a.exit_tab:active{
      border: none;
  }
  .nav-tabs-custom > .nav-tabs > li.active > a {
    background-color: #EEEFF4 !important;
    cursor: default
  }
</style>