/**
 * This vuex store is used for manipulation with data about maintainers of the user.
 */

import api from '../classes/api';
import router from '../../router';

const state = {
  maintainers: [],
  minMaintainers: [],
  maintainer: {},
  mnt_id: null
};

const getters = {
  allMaintainers: state => state.maintainers,
  currentMaintainer: state => state.maintainer,
  currentMaintainerId: state => state.mnt_id,
  minMaintainers: state => state.minMaintainers,
};

const actions = {
  async fetchMaintainers({ commit }) {
    const res = await api.get('maintainers');
    commit('setMaintainers', res.mnt);
  },

  async fetchMaintainer({ commit }, id) {
    const res = await api.get(`maintainers/${id}`);
    const cars = await api.get(`maintainers/${id}/cars`);
    const issues = await api.get(`maintainers/${id}/issues`);
    res.cars = cars;
    res.issues = issues;
    commit('setMaintainer', res);
  },

  async fetchMinMaintainers({ commit }, customerid) {
    const res = await api.get(`customers/${customerid}/maintainers/min`);
    commit('setMinMaintainers', res.mnt);
    return res
  },

  makeCurrentMnt({ commit }, id) {
    router.push({name: 'MaintainerDetail'});
    commit('setCurrentMntId', id)
  },

  async addMaintainer({ commit }, data) {
    const res = await api.post(`maintainers`, data);
    
    if(!res.existing)
    commit('newMaintainer', data);
    return res;
  },

  async updateMaintainer({ commit }, data) {
    const res = await api.put(`maintainers/${data.maintainerid}`, data);

    if (res.status == 200) {
      commit('updateMaintainer', data);
    } else {
      throw new Error(`Status: ${res.status}\n ${res.data}`);
    }
      
  },

  async deleteMaintainer({ commit }, id) {
    const res = await api.delete(`maintainers/${id}`);

    if(res.msg == "Success.")
      commit('removeMaintainer', id);
    
  }
};

const mutations = {
  setMaintainers: (state, maintainers) => (state.maintainers = maintainers),

  setMinMaintainers: (state, minMaintainers) => (state.minMaintainers = minMaintainers),

  setMaintainer: (state, maintainer) => (state.maintainer = maintainer),

  setCurrentMntId: (state, mnt_id) => (state.mnt_id = mnt_id),

  newMaintainer: (state, maintainer) => state.maintainers.push(maintainer),

  removeMaintainer: (state, id) =>
    (state.maintainers = state.maintainers.filter(maintainer => maintainer.maintainerid !== id)),
  
  updateMaintainer: (state, updMaintainer) => {
    state.maintainers.findIndex(maintainer => maintainer.id === updMaintainer.id);
  }

};

export default {
  state,
  getters,
  actions,
  mutations
};
