const helpers = {
    install(Vue) {
        Vue.prototype.$helpers = {
            padNum(num, size) {
                var s = num + "";
                while (s.length < size) s = "0" + s;
                return s;
            },
            filterCheck(filter, instring)
            {
                return instring.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(filter.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase())
            },
            getTimeAndDate(epochTime) {
                const dt = new Date(epochTime * 1000);
                const day = dt.getDate();
                const mth = dt.getMonth() + 1;
                const yrs = dt.getFullYear();
                const hrs = dt.getHours();
                const min = dt.getMinutes();
                return `${day}.${mth}.${yrs}  ${hrs}:${min}`;
            },
            getDate(epochTime) {
                const dt = new Date(epochTime * 1000);
                const day = dt.getDate();
                const mth = dt.getMonth() + 1;
                const yrs = dt.getFullYear();
                return `${day}.${mth}.${yrs}`;
            },
        }
    }
}
  
export default helpers
