<template>
    <li class="dropdown user user-menu">
        <a href="#" class="dropdown-toggle" data-toggle="dropdown">
            <!-- <img src="./dist/img/user2-160x160.jpg" class="user-image" alt="User Image"> -->
            <i class="fa fa-user"></i>
            <span class="hidden-xs">{{group.company_name}}</span>
        </a>
        <ul class="dropdown-menu">
            <!-- User image -->
            <li class="user-header">
            <img src="../assets/img/user2-160x160.jpg" class="img-circle" alt="User Image">
            <p>
                {{group.company_name}}
                <small>{{user.fullname}}</small>
            </p>
            </li>
            
            <!-- Menu Footer-->
            <li class="user-footer">
            <div class="pull-left">
                <a href="/#/personal_info" class="btn btn-default btn-flat">Nastavení osobních údajů</a>
            </div>
            <div class="pull-right">
                <a href="#" @click="signOut" class="btn btn-default btn-flat">Odhlásit</a>
            </div>
            </li>
        </ul>
    </li>
</template>

<script>
import api from '../store/classes/api';
import { mapGetters, mapActions } from "vuex";

export default {
    name: "UserMenu",
    methods: {
        ...mapActions(["fetchUser"]),
        async signOut(err){
            err.preventDefault();
            await api.logout();
            location.reload();
        },
        getInfo(){
            console.log(this.user);
        }
    },
    computed: mapGetters(["user", "group"]),
    created() {
        this.fetchUser().then(() => {
            // window.ybug_settings.feedback.email = this.user.email;
        });
    }
}
</script>
