/**
 * Axios interceptor for checking if the jwt is expired.
 */

import axios from 'axios';
import router from '../../router';

export default {

  // API host
  host: process.env.VUE_APP_API,

  // API configuration
  api_config: {
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        Authorization: 'jwt ' + localStorage.getItem('accessData')
    }
  },

  jwtReloadConf() {
    this.api_config.headers['Authorization'] = `jwt ${localStorage.getItem('accessData')}`;
  },

  jwtCheckInit() {

    axios.interceptors.response.use( (response) => {
      // Return a successful response back to the calling service
      return response;
    }, async (error) => {

      // Return any error which is not due to authentication back to the calling service
      if (error.response.status !== 401) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }

      // Logout user if token refresh didn't work or user is disabled
      if (error.config.url == this.host + 'auth/refresh') {
        
          localStorage.clear();
          router.push({name: 'Login'});

          return new Promise((resolve, reject) => {
              reject(error);
          });
      }

      // Try request again with new token
      try {

        // Fetch token
        const res = await axios.post(this.host + 'auth/refresh', JSON.stringify({
            refreshData: localStorage.getItem('refreshData')
        }), { headers: { 'Content-Type': 'application/json' }, withCredentials: true });

        // Store the token
        localStorage.setItem('accessData', res.data.accessData)

        // New request with new token
        let config = error.config;
        config.headers['Authorization'] = `jwt ${localStorage.getItem('accessData')}`;

        // Update API configuration
        this.api_config.headers['Authorization'] = config.headers['Authorization'];

        return new Promise((resolve, reject) => {
          axios.request(config).then(response => {
            resolve(response);
          }).catch((error) => {
            reject(error);
          })
        });

      } catch (error) {
          throw error;
      }      
      
    });
  }

}

