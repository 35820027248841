/**
 * This vuex store is used for manipulation with data about cars of the user.
 */

import api from '../classes/api';
import router from '../../router';

const state = {
  cars: [],
  files: [],
  carsMin: [],
  car: {},
  car_id: null
};

const getters = {
  allCars: state => state.cars,
  allCarFiles: state => state.files,
  minCars: state => state.carsMin,
  currentCar: state => state.car,
  currentCarId: state => state.car_id
};

const actions = {
  async fetchCars({ commit }) {
    const res = await api.get('cars');
    
    if (res[0]) {
      commit('setCars', res);
      return true;
    }
      
  },

  async fetchMinCars({ commit }) {
    const res = await api.get('cars/min');
    commit('setMinCars', res.car);
  }, 

  async fetchCustomerMinCars({ commit }, id) {
    const res = await api.get(`customers/${id}/cars/min`);
    commit('setMinCars', res.car);
  }, 

  async fetchCar({ commit }, id) {
    const res = await api.get(`cars/${id}`);
    const issues = await api.get(`cars/${id}/issues`);
    res.issues = issues;
    commit('setCar', res);
  },

  makeCurrentCar({ commit }, id, idToClose = 0) {
      router.push({name: 'CarDetail'});
      commit('setCurrentCarId', id)
      //if(idToClose != 0) this.closeTab(idToClose);
  },

  async addCar({ commit }, data) {
    const res = await api.post(`cars`, data);
    
    if(res.id !== undefined)
    commit('newCar', data);
    return res;
  },
  
  async updateCar({ commit }, data) {
    const res = await api.put(`cars/${data.carid}`, data);
    if (res.status == 200) {
      commit('updateIssue', data);
    } else {
      throw new Error(`Status: ${res.status}\n ${res.data}`);
    }
    
  },

  async deleteCar({ commit }, id) {
    const res = await api.delete(`cars/${id}`);

    if(res.msg == "Success.")
      commit('removeCar', id);
  },
  
  async uploadCarFile({commit}, data) {
    const res = await api.postFormData(`cars/upload/${data.id}`, data.formData);

    if(res[0])
      commit('newCarFile', res);
    
    return res;
  },

  async deleteCarFile({ commit }, id) {
    const res = await api.delete(`cars/file/${id}`);

    if(res && res.msg == "Success.") {
      commit('removeCarFile', id);
    } else {
      throw new Error(`Forbidden`);
    }
  },

  async fetchCarFiles({ commit }, id) {
    const res = await api.get(`cars/${id}/files`);
    commit('setCarFiles', res); 
  },

  async toggleFileVisibility({ commit }, data) {
    const res = await api.post(`cars/file/${data.fileid}/visibility`, data);
    if(res && res.msg == "Success.") {
      commit('changeFileVisibility', data.fileid)
    }
  }

};

const mutations = {
  setCars: (state, cars) => (state.cars = cars),

  setCarFiles: (state, files) => (state.files = files),
  
  setMinCars: (state, carsMin) => (state.carsMin = carsMin),

  setCar: (state, car) => (state.car = car),

  setCurrentCarId: (state, car_id) => (state.car_id = car_id),

  newCar: (state, car) => state.cars.push(car),

  removeCar: (state, id) =>
    (state.cars = state.cars.filter(car => car.carid !== id)),

  removeCarFile: (state, id) =>
    (state.files = state.files.filter(file => file.fileid !== id)),

  newCarFile: (state, files) => {
    if(state.files == '') {
      state.files = files;
    } else {
      files.forEach(file => {
        state.files.push(file)
      });
    }
  },
  
  updateCar: (state, updCar) => {
    state.cars.findIndex(car => car.id === updCar.id);
  },

  changeFileVisibility: (state, id) => {
    state.files = state.files.map(file => file.fileid == id ? {...file, visible: file.visible == 0 ? 1 : 0} : file);
    console.log(state.files)
  }

};

export default {
  state,
  getters,
  actions,
  mutations
};
