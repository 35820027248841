/**
 * This vuex store is used for manipulation with data about emails of the station.
 */

 import api from '../classes/api';
 
 const state = {
   emails: []
 };
 
 const getters = {
   allEmails: state => state.emails
 };
 
 const actions = {

   async fetchEmails({ commit }) {
     const res = await api.get('email');
     
     if (res[0]) {
       commit('setEmails', res);
       return true;
     }
       
   },
 
 };
 
 const mutations = {
   setEmails: (state, emails) => (state.emails = emails)
 };
 
 export default {
   state,
   getters,
   actions,
   mutations
 };
 