/**
 * Pages object that stores their information.
 * Roles: all, accAdmin, acc, maiAdmin, mai
 */

const Pages = [
    {
        id: 0,
        name: "Dashboard",
        lng_name: "Hlavní stránka",
        path: "/dashboard",
        in_sid: true,
        in_nav: true,
        current: true,
        icon: 'fa-dashboard',
        meta: {
            auth: true,
            role: 'all',
        }
    },
    {
        id: 1,
        name: "Customers",
        lng_name: "Zákazníci",
        path: "/customers",
        in_sid: true,
        in_nav: false,
        current: false,
        icon: 'fa-address-book',
        meta: {
            auth: true,
            role: 'acc'
        }
    },
    {
        id: 2,
        name: "Maintainers",
        lng_name: "Osoby",
        path: "/maintainers",
        in_sid: true,
        in_nav: false,
        current: false,
        icon: ' fa-users',
        meta: {
            auth: true,
            role: 'acc'
        }
    },
    {
        id: 3,
        name: "Cars",
        lng_name: "Přehled aut",
        path: "/cars",
        in_sid: true,
        in_nav: false,
        current: false,
        icon: 'fa-car',
        meta: {
            auth: true,
            role: 'all'
        }
    },
    {
        id: 4,
        name: "Issues",
        lng_name: "Úkony",
        path: "/issues",
        in_sid: true,
        in_nav: false,
        current: false,
        icon: 'fa-tasks',
        meta: {
            auth: true,
            role: 'all'
        }
    },
    {
        id: 5,
        name: "Forbidden",
        lng_name: "Nepřístupné 403",
        path: "/forbidden",
        in_sid: false,
        in_nav: false,
        current: false,
        icon: 'fa-table',
        meta: {
            auth: true,
            role: 'acc'
        }
    },
    {id: 6,
        name: "NotFound",
        lng_name: "Nenalezeno 404",
        path: "/not_found",
        in_sid: false,
        in_nav: false,
        current: false,
        icon: 'fa-thumb-tack',
        meta: {
            auth: true,
            role: 'all'
        }
    },
    {
        id: 7,
        name: "PersonalInfo",
        lng_name: "Osobní informace",
        path: "/personal_info",
        in_sid: false,
        in_nav: false,
        current: false,
        icon: 'fa-info',
        meta: {
            auth: true,
            role: 'all'
        }
    },
    {
        id: 8,
        name: "CustomerDetail",
        lng_name: "Detail zákazníka",
        path: "/customer_detail",
        in_sid: false,
        in_nav: false,
        current: false,
        icon: 'fa-id-card',
        meta: {
            auth: true,
            role: 'acc'
        }
    },
    {
        id: 9,
        name: "CustomerAdd",
        lng_name: "Přidat zákazníka",
        path: "/customer_add",
        in_sid: false,
        in_nav: false,
        current: false,
        icon: 'fa-user-plus',
        meta: {
            auth: true,
            role: 'acc'
        }
    },
    {
        id: 10,
        name: "CarDetail",
        lng_name: "Detail auta",
        path: "/car_detail",
        in_sid: false,
        in_nav: false,
        current: false,
        icon: 'fa-car',
        meta: {
            auth: true,
            role: 'all'
        }
    },
    {
        id: 11,
        name: "IssueDetail",
        lng_name: "Detail úkonu",
        path: "/issue_detail",
        in_sid: false,
        in_nav: false,
        current: false,
        icon: 'fa-wrench',
        meta: {
            auth: true,
            role: 'all'
        }
    },
    {
        id: 12,
        name: "CarAdd",
        lng_name: "Přidat auto",
        path: "/car_add",
        in_sid: false,
        in_nav: false,
        current: false,
        icon: 'fa-plus',
        meta: {
            auth: true,
            role: 'acc'
        }
    },
    {
        id: 13,
        name: "IssueAdd",
        lng_name: "Přidat úkon",
        path: "/issue_add",
        in_sid: false,
        in_nav: false,
        current: false,
        icon: 'fa-plus',
        meta: {
            auth: true,
            role: 'acc'
        }
    },
    {
        id: 14,
        name: "MaintainerDetail",
        lng_name: "Detail osoby",
        path: "/person_detail",
        in_sid: false,
        in_nav: false,
        current: false,
        icon: 'fa-user',
        meta: {
            auth: true,
            role: 'all'
        }
    },
    {
        id: 15,
        name: "MaintainerAdd",
        lng_name: "Přidat osobu",
        path: "/person_add",
        in_sid: false,
        in_nav: false,
        current: false,
        icon: 'fa-user-plus',
        meta: {
            auth: true,
            role: 'acc'
        }
    },
    {
        id: 16,
        name: "Calendar",
        lng_name: "Kalendář",
        path: "/calendar",
        in_sid: true,
        in_nav: false,
        current: false,
        icon: 'fa-calendar',
        meta: {
            auth: true,
            role: 'all'
        }
    },
    {
        id: 17,
        name: "Operations",
        lng_name: "Seznam operací",
        path: "/list_operations",
        in_sid: true,
        in_nav: true,
        current: false,
        icon: 'fa-wrench',
        meta: {
            auth: true,
            role: 'acc'
        }
    },
    {
        id: 18,
        name: "OperationAdd",
        lng_name: "Přidat operaci",
        path: "/operation_add",
        in_sid: false,
        in_nav: false,
        current: false,
        icon: 'fa-plus',
        meta: {
            auth: true,
            role: 'acc'
        }
    },
    {
        id: 19,
        name: "OperationDetail",
        lng_name: "Detail operace",
        path: "/operation_detail",
        in_sid: false,
        in_nav: false,
        current: false,
        icon: 'fa-wrench',
        meta: {
            auth: true,
            role: 'acc'
        }
    },
    {
        id: 20,
        name: "EmployeeAdd",
        lng_name: "Přidat zaměstnance",
        path: "/employee_add",
        in_sid: false,
        in_nav: false,
        current: false,
        icon: 'fa-wrench',
        meta: {
            auth: true,
            role: 'acc'
        }
    },
    {
        id: 21,
        name: "EmailHistory",
        lng_name: "Seznam odeslaných emailů",
        path: "/email_history",
        in_sid: true,
        in_nav: false,
        current: false,
        icon: 'fa-history',
        meta: {
            auth: true,
            role: 'acc'
        }
    },
    {
        id: 22,
        name: "SummaryEmail",
        lng_name: "Hromadné odeslání emailu",
        path: "/summary_email",
        in_sid: true,
        in_nav: false,
        current: false,
        icon: 'fa-envelope',
        meta: {
            auth: true,
            role: 'acc'
        }
    },
    {
        id: 23,
        name: "Sales",
        lng_name: "Přehled tržeb",
        path: "/sales",
        in_sid: false,
        in_nav: false,
        current: false,
        icon: 'fa-money',
        meta: {
            auth: true,
            role: 'acc'
        }
    },
    {
        id: 24,
        name: "SalesPlanned",
        lng_name: "Přehled plánu tržeb",
        path: "/sales_planned",
        in_sid: false,
        in_nav: false,
        current: false,
        icon: 'fa-line-chart',
        meta: {
            auth: true,
            role: 'acc'
        }
    },
    {
        id: 25,
        name: "Invoices",
        lng_name: "Faktury",
        path: "/invoices",
        in_sid: true,
        in_nav: false,
        current: false,
        icon: 'fa-sticky-note',
        meta: {
            auth: true,
            role: 'acc'
        }
    },
];

export default Pages;
