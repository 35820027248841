/**
 * This vuex store is used for manipulation with user data.
 */

import api from '../classes/api';

const state = {
  user: {},
  group: {},
  stats: {},
  employees: [],
  employee: {}
};

const getters = {
  user: state => state.user,
  group: state => state.group,
  stats: state => state.stats,
  employees: state => state.employees,
  currentEmployee: state => state.employee
};

const actions = {
    // Fetch user data
    async fetchUser({ commit }) {
        const res = await api.get('info');

        const user = localStorage.getItem('user')

        res.user.uid = JSON.parse(user).uid;
        res.user.cus = JSON.parse(user).cus;
        res.user.admin = JSON.parse(user).pow;
        res.user.isEmployee = JSON.parse(user).isEmployee
        res.group.access = JSON.parse(user).acc;
        

        if (res.user.cus == 0) {
          const timespent = await api.get('billing/organization/timespent');
          res.group.timespent = timespent;
          const cars_left = await api.get('info/remainingCars');
          res.group.remaining_cars = cars_left.remaining_cars;
        }
        
        commit('setUser', res.user);
        commit('setGroup', res.group);
    },
    async fetchStats({ commit }) {
        const res = await api.get('info/stats');
        commit('setStats', res.stats);
    },

    async fetchEmployees({ commit })
    {
        const res = await api.get('info/employees');
        commit('setEmployees', res);
    },
    async addEmployee({ commit }, data)
    {
        const res = await api.post('info/employees', data);

        if (res.code != "EXCEPTION" || res.code != "EMAIL_INUSE")
        {
            commit('newEmployee', data);
        }

        return res;
    },
    async setPhone({ commit }, data)
    {
      const res = await api.post('info/phone', data);
      return res;
    }
};

const mutations = { 
  setUser: (state, user) => (state.user = user),
  setGroup: (state, group) => (state.group = group),
  setStats: (state, stats) => (state.stats = stats),
  setEmployees: (state, employees) => (state.employees = employees),
  newEmployee: (state, employee) => state.employee = employee,
};

export default {
  state,
  getters,
  actions,
  mutations
};
